import useDeviceType from '@/hooks/useDeviceType';
import { IFindYourPerfectEscapeProps } from './types';
import GridCards from './grid-cards';
import SliderCards from '@/components/base/slider-cards';
import { Container } from '@/components/base/gridview';
import { motion, useInView } from 'framer-motion';
import { useRef } from 'react';

const animations = {
  container: {
    hidden: {
      opacity: 0,
    },
    show: {
      opacity: 1,
      transition: {
        duration: 0.5,
        delayChildren: 0.3,
        ease: [0.25, 1, 0.5, 1],
      },
    },
  },
  header: {
    hidden: {
      opacity: 0,
      x: -100,
    },
    show: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.5,
        ease: [0.25, 1, 0.5, 1],
      },
    },
  },
};

const FindYourPerfectEscape: React.FC<IFindYourPerfectEscapeProps> = ({
  cards,
  firstCard,
  title,
  mbDesktop = 100,
  mtDesktop = 100,
  mtLaptop = 100,
  mbLaptop = 100,
  mbMobile = 50,
  mtMobile = 50,
}) => {
  const deviceType = useDeviceType();
  const ref = useRef<HTMLElement>(null);
  const isInView = useInView(ref, { once: true });

  const checkDevice = () => {
    if (deviceType !== 'mobile' && deviceType !== 'tablet') {
      return <GridCards cards={cards} firstCard={firstCard} />;
    }

    return (
      <>
        <motion.h3
          variants={animations.header}
          initial="hidden"
          animate={`${isInView ? 'show' : 'hidden'}`}
        >
          {title}
        </motion.h3>

        <SliderCards cards={cards} />
      </>
    );
  };

  return (
    <motion.section
      variants={animations.container}
      initial="hidden"
      animate={`${isInView ? 'show' : 'hidden'}`}
      ref={ref}
      viewport={{ once: true, amount: 0.25 }}
      className="find-your-perfect-escape"
      style={{
        marginTop:
          deviceType === 'mobile'
            ? mtMobile
            : deviceType === 'laptop'
            ? mtLaptop
            : mtDesktop,
        marginBottom:
          deviceType === 'mobile'
            ? mbMobile
            : deviceType === 'laptop'
            ? mbLaptop
            : mbDesktop,
      }}
    >
      <Container>{checkDevice()}</Container>
    </motion.section>
  );
};

export default FindYourPerfectEscape;
