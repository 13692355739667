import { IGridCardsProps } from './types';
import Card from '../../card';
import { UICard } from '../../card/types';
import { motion, useInView } from 'framer-motion';
import Anchor from '@/components/base/anchor';
import { useRef } from 'react';

const animations = {
  container: {
    hidden: {
      opacity: 0,
    },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.15,
        delayChildren: 0.3,
        duration: 0.3,
        ease: [0.25, 1, 0.5, 1],
      },
    },
  },
  card: {
    hidden: {
      opacity: 0,
      y: 50,
    },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.3,
        ease: [0.25, 1, 0.5, 1],
      },
    },
  },
  header: {
    hidden: {
      opacity: 0,
      x: -100,
    },
    show: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.5,
        ease: [0.25, 1, 0.5, 1],
      },
    },
  },
};

const GridCards: React.FC<IGridCardsProps> = ({ cards = [], firstCard }) => {
  const ref = useRef<HTMLDivElement>(null);
  const isInView = useInView(ref, { once: true });

  return (
    <motion.div
      className="grid-cards-container"
      variants={animations.container}
      initial="hidden"
      animate={`${isInView ? 'show' : 'hidden'}`}
      ref={ref}
      viewport={{ once: true }}
    >
      <motion.div className="grid-cards-description" variants={animations.card}>
        <motion.h3 variants={animations.header} viewport={{ once: true }}>
          {firstCard?.title}
        </motion.h3>

        <p>{firstCard?.description}</p>

        <Anchor {...firstCard?.anchor} />
      </motion.div>
      {cards?.map((card: UICard, index: number) => (
        <Card
          key={index}
          image={card.image}
          title={card.title}
          description={card.description}
          like={card.like}
          day={card.day}
          link={card.link}
        />
      ))}
    </motion.div>
  );
};

export default GridCards;
